@font-face {
  font-family: 'proxima_novabold';
  src: url('/assets/fonts/proximanova-bold-webfont.woff2') format('woff2'),
  url('/assets/fonts/proximanova-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'proxima_novaregular';
  src: url('/assets/fonts/proximanova-regular-webfont.woff2') format('woff2'),
  url('/assets/fonts/proximanova-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'proxima_novasemibold';
  src: url('/assets/fonts/proximanova-semibold-webfont.woff2') format('woff2'),
  url('/assets/fonts/proximanova-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'proxima_novaitalic';
  src: url('/assets/fonts/proximanova-light-italic-webfont.woff2') format('woff2'),
  url('/assets/fonts/proximanova-light-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

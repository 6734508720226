/* You can add global styles to this file, and also import other style files */
@import "styles/fonts";
@import "styles/variables";

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

/* Importing Bootstrap Icon CSS file. */
@import "bootstrap-icons/font/bootstrap-icons.css";


html, body{
  height: 100%;
  background-color: $background-color;
}

.text-green {
  color: $asvin-green;
}
.text-gray {
  color: $text-gray;
}
.text-light-gray {
  color: $text-light-gray;
}
.bg-gray {
  background-color: $background-gray;
}
.table-gray {
  --bs-table-bg: $background-gray;
}
.btn-green {
  color: white;
  background-color: $asvin-green;

  &:hover {
    background-color: $asvin-green
  }
}

a,
a:hover {
  text-decoration: none;
  color: $link-color;
}

ul, ol {
  list-style-type: none;
  padding: 0;
}

//Custom Nav Bar
.nav-tabs {
  :first-child .nav-link {
    border-top-left-radius: 0.375rem;
  }

  :last-child .nav-link {
    border-top-right-radius: 0.375rem;
  }

  .nav-link {
    color: #707070;
    background-color: white;
    border-color: white;
    padding: 8px 50px 8px 50px;
    border-top-left-radius: 0;
    border-top-right-radius: 0.375rem;
    box-shadow: 1px 0 5px 1px #CCCCCC;
    margin-right: -4px;
    font-family: $font-family-bold;

    &.active,
    &.show .nav-link {
      color: white;
      background-color: $asvin-green;
      border-color: $asvin-green;
      border-top-right-radius: 0.375rem;
      position: relative;
      &:hover {
        border-color: $asvin-green;
      }
    }

    &.disabled,
    &:disabled {
      color: #F5F5F5;
      background-color: white;
      border-color: white;
    }

    &:hover {
      border-color: white;
      isolation: unset;
    }
  }

  &.reversed {
    border-bottom: none;
    border-top-right-radius: 0;
    border-top-left-radius: 0;

    :first-child .nav-link {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0.375rem;
    }

    :last-child .nav-link {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0.375rem;
    }

    .nav-link {
      cursor: pointer;
      box-shadow: 1px 2px 5px 1px #CCCCCC;

      &.active,
      &.show .nav-link {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0.375rem;
        border-bottom-left-radius: 0.375rem;
      }
    }
  }
}

//Sortable Table
th[sortable] {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
}

th[sortable].desc:before,
th[sortable].asc:before {
  content: '';
  display: block;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAmxJREFUeAHtmksrRVEUx72fH8CIGQNJkpGUUmakDEiZSJRIZsRQmCkTJRmZmJgQE0kpX0D5DJKJgff7v+ru2u3O3vvc67TOvsdatdrnnP1Y///v7HvvubdbUiIhBISAEBACQkAICAEhIAQ4CXSh2DnyDfmCPEG2Iv9F9MPlM/LHyAecdyMzHYNwR3fdNK/OH9HXl1UCozD24TCvILxizEDWIEzA0FcM8woCgRrJCoS5PIwrANQSMAJX1LEI9bqpQo4JYNFFKRSvIgsxHDVnqZgIkPnNBM0rIGtYk9YOOsqgbgepRCfdbmFtqhFkVEDVPjJp0+Z6e6hRHhqBKgg6ZDCvYBygVmUoEGoh5JTRvIJwhJo1aUOoh4CLPMyvxxi7EWOMgnCGsXXI1GIXlZUYX7ucU+kbR8NW8lh3O7cue0Pk32MKndfUxQFAwxdirk3fHappAnc0oqDPzDfGTBrCfHP04dM4oTV8cxr0SVzH9FF07xD3ib6xCDE+M+aUcVygtWzzbtGX2rPBrEUYfecfQkaFzYi6HjVnGBdtL7epqAlc1+jRdAap74RrnPc4BCijttY2tRcdN0g17w7HqZrXhdJTYAuS3hd8z+vKgK3V1zWPae0mZDMykadBn1hTQBLnZNwVrJpSe/NwEeDsEwCctEOsJTsgxLvCqUl2ACftEGvJDgjxrnBqkh3ASTvEWrIDQrwrnJpkB3DSDrGW7IAQ7wqnJtkBnLRztejXXVu4+mxz/nQ9jR1w5VB86ejLTFcnnDwhzV+F6T+CHZlx6THSjn76eyyBIOPHyDakhBAQAkJACAgBISAEhIAQYCLwC8JxpAmsEGt6AAAAAElFTkSuQmCC')
  no-repeat;
  background-size: 22px;
  width: 22px;
  height: 22px;
  float: left;
  margin-left: -22px;
}

th[sortable].desc:before {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}

.card:hover {
  background-color: rgb(240, 242, 243);
}

.selected{
  background-color: rgb(240, 242, 243);
}

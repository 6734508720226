/* Layout */
$header-height: 55px;
$sidebar-width: 265px;

/* Colors */
$asvin-green: #92C029;
$background-color: #F5F5F5;
$background-gray: #F2F2F2;
$asset-low: #AFCA0B;
$asset-medium: #F19100;
$asset-high: #D10C15;
$text-gray: #707070;
$text-light-gray: #a8adb2;

$asvin-green-filter: invert(64%) sepia(13%) saturate(2027%) hue-rotate(37deg) brightness(103%) contrast(94%);

$bs-nav-tabs-link-active-bg: $asvin-green;

/* Fonts */
$font-family-regular: 'proxima_novaregular';
$font-family-semibold: 'proxima_novasemibold';
$font-family-bold: 'proxima_novabold';
$font-family-italic: 'proxima_novaitalic';

$font-family-sans-serif: $font-family-regular;
$headings-font-family: $font-family-semibold;
$btn-font-family: $font-family-semibold;

$link-color: #000000;

/* Custom Inputs */
$form-range-thumb-bg: $asvin-green;
$form-range-thumb-active-bg: $asvin-green;
$form-range-thumb-focus-box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(146, 192, 41, 0.25);
$form-range-track-bg: $background-gray;
$form-range-thumb-height: 1.25rem;
$form-range-thumb-width: 1.25rem;
$form-range-track-height: 0.75rem;

$form-check-input-checked-border-color: $asvin-green;
$form-check-input-checked-bg-color: $asvin-green;
$form-check-input-width:  1.25em;
$form-check-input-focus-box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(146, 192, 41, 0.25);

/* Table variables */
$table-hover-bg: rgba($link-color, 0.20);
